'use strict';

console.log('Hello Beacon Austin!');

var Beacon = {
    showHideContent: {
        init: function() {
            $('[data-toggle]').on('click', function(){
                var $parent = $(this).closest('[data-toggle-parent]');
                $parent.toggleClass('opened');
                $parent.find('[data-toggle-content]').slideToggle();
            });
        }
    },
    bioViewer: {
        init: function() {
            var $bioViewer = $('[data-bio-viewer]');

            $('[data-bio-toggle]').on('click', function(){
                var $parent = $(this).closest('[data-bio-toggle-parent]');
                console.log('width: ', $(window).width());
                if ($(window).width() > 560) {
                    var target = parseInt($(this).data('bio-toggle'), 10);
                    $bioViewer.html($parent.find('[data-bio-content]').html());

                    // clean up other arrow classes
                    $bioViewer.removeClass('target-3');
                    $bioViewer.removeClass('target-2');
                    $bioViewer.removeClass('target-1');

                    // set arrow
                    $bioViewer.toggleClass('target-' + target);

                    $bioViewer.slideDown();
                }
                else {
                    $parent.find('.bio').slideToggle();
                }
            });
        }
    },
    sliders: {
        init: function(){
            $('[data-slider-testimonials]').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 4000,
                arrows: false,
                dots: true
            });
            $('[data-slider-banner]').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                autoplaySpeed: 4000,
                autoplay: true,
                pauseOnHover: false
            });
        }
    },
    contactForm: {
        init: function(){
            var $form = $('form#contact-form');

            $form.submit(function(e) {
                console.log('sending form');
                e.preventDefault();

                var $thisForm = $(this);
                var $submitButton = $thisForm.find('.submit-wrap');

                $submitButton.attr('disabled', 'disabled');

                var formData = $form.serialize();

                $.ajax({
                    url : '',
                    method: 'POST',
                    data: formData,
                    success: function(response, textStatus, jqXHR){
                        console.log('success');
                        $thisForm.find('.thank-you-msg').fadeIn();
                        $submitButton.fadeOut();
                    },
                    error: function(jqXHR, textStatus, errorThrown){
                        console.log('error');
                        alert('Make sure all fields have been filled');
                        $submitButton.removeAttr('disabled');
                    }
                });

                return false;
            });
        }
    },
    samePageScroll: {
        init: function(){
            $('a[href*="#"]').on('click', function(e) {
                e.preventDefault()

                // hide mobile menu
                $('[data-mobile-nav]').slideUp();

                $('html, body').animate({
                    scrollTop: $($(this).attr('href')).offset().top - $('.mainNav').outerHeight(),
                },
                    500,
                    'linear'
                );
            });
        }
    },
    stickyHeader: {
        init: function() {
            $("[data-sticky-nav]").headroom({
                offset : 400,
            });
        }
    },
    mobileNav: {
        init: function() {
            $('[data-mobile-nav-toggle]').on('click', function(){
                $('[data-mobile-nav]').slideToggle();
            });
        }
    }
}

$(function(){
    Beacon.showHideContent.init();
    Beacon.bioViewer.init();
    Beacon.sliders.init();
    Beacon.contactForm.init();
    Beacon.samePageScroll.init();
    Beacon.stickyHeader.init();
    Beacon.mobileNav.init();
});
